import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { BannerWrapper, ContentWrapper, GameContainer, LeftOptionsFirst, NextBtn, OnboardingWrapper, OptionsFirstImg, ShareImg, StepContent, TextBtn, TopOptions, TopOptionsLeft } from '../gamePlay/styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appImages } from '../../themes/imageConstant';
import { appColors } from '../../themes/colorConst';
import { BottomBtnContainer, CrossImg, MainContainer, MainWrapper, PuzzleContainer, ResultText, SectionContainer, StarImg, TextWrapper } from './styles';
import ModalCommon from '../../components/modal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { botStepsInitiate, getWordInitiate, updateGamePlayStates, userEventsInitiate } from '../../redux/actions/game';
import Loader from '../../components/loader';
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

const Result = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const gaEventTrackerForTryAgain = useAnalyticsEventTracker('Try Again');


  const reducerStates = useSelector(state => state.game)
  const {
    botStepsLoader,
    botStepsSuccess,
    botSteps
  } = reducerStates

  const {
    isResultVisible,
    handleVisibleResultScreen,
    handleVisibleBotSolutionScreen,
    yourResult,
    botStepsCount,
    payloadData,
    challengeDifficulty,
    handleCloseResultScreen,
    handOpenShareModal,
    handleVisiblePerformanceScreen
  } = props

  const [isRatingModalVisible, setIsRatingModalVisible] = React.useState(false);

  const handleCloseModal = () => {
    setIsRatingModalVisible(false);
  }

  React.useEffect(() => {
    dispatch(updateGamePlayStates(false, 'yourResultSuccess'))
    return () => {
      localStorage.setItem('isGamePlayed', true)
    }
  }, [])

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "CHANGE IT",
          text: `I managed to Change It in ${yourResult?.attempt || 10}, can you beat me?`,
          // text: 'Try it out',
          url: 'https://changeitgame.com/',
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing not supported on this device.');
    }
  };


  const puzzleDifficulty = (type) => {
    return (
      <NextBtn
        variant="contained"
        size="small"
        height={
          screenWidth > maximumTabletWidth
            ? "31px"
            : (31 / screenWidth) * 100 + "vw"
        }
        width={
          screenWidth > maximumTabletWidth
            ? "79px"
            : (79 / screenWidth) * 100 + "vw"
        }
        backgroundcolor={
          type == 'Hard'
            ?
            appColors.hardColor
            :
            type == 'Moderate'
              ?
              appColors.moderateColor
              :
              type == 'Very Hard'
                ?
                appColors.veryHardColor
                :
                type == 'Easy'
                  ?
                  appColors.successBtnColor
                  :
                  appColors.blackColor
        }
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={
            screenWidth > maximumTabletWidth
              ? "12px"
              : (12 / screenWidth) * 100 + "vw"
          }
          fontFamily={'poppins500'}
        >
          {type || ''}
        </TextBtn>
      </NextBtn>
    )
  }

  const list = (anchor) => (
    <MainWrapper>
      <ContentWrapper id={"contentContainer"} cstmheight>
        <MainContainer>
          <SectionContainer cstmpadding={screenWidth > maximumTabletWidth ? "15px 0 0 0" : (10 / screenWidth) * 100 + "vh 0 0 0"} postionrelative={'relative'}>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth) * 100 + "vw"}
              cstmpadding={screenWidth > maximumTabletWidth ? "10px 0 0 0" : (10 / screenWidth) * 100 + "vw 0 0 0"}
              fontfamily={'poppins500'}
              color={appColors.greyTextColor}
            >
              Congratulations!

            </TextWrapper>
            <CrossImg
              onClick={() => {
                // localStorage.setItem("gameCompleted", true);
                dispatch(updateGamePlayStates(true, 'gameCompleted'))
                handleVisibleResultScreen();
              }}
              src={appImages.cross}
            />
          </SectionContainer>
          <SectionContainer>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
            >
              You Changed It in
            </TextWrapper>
          </SectionContainer>
          <SectionContainer
            gap={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth * 100) + "vw"}
            alignitems={'center'}
          >
            <NextBtn
              visibiltyhidden={yourResult?.restartCount > 0 ? false : true}
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? "31px"
                  : (31 / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? "82px"
                  : (82 / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.blackColor}
            >
              <TextBtn
                color={appColors.whiteColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "12px"
                    : (12 / screenWidth) * 100 + "vw"
                }
                fontFamily={'poppins500'}
              >
                {`${yourResult?.actualAttempts} Changes` || '0 Changes'}
              </TextBtn>
            </NextBtn>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "52px" : (52 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins600'}
              color={appColors.blackColor}
            >
              {yourResult?.attempt || 0}
            </TextWrapper>
            <NextBtn
              visibiltyhidden={yourResult?.restartCount > 0 ? false : true}
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? "31px"
                  : (31 / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? "82px"
                  : (82 / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.dangerColor}
            >
              <TextBtn
                color={appColors.whiteColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "12px"
                    : (12 / screenWidth) * 100 + "vw"
                }
                fontFamily={'poppins500'}
              >
                {yourResult?.restartCount > 0 ? `+ ${yourResult?.restartCount} Restart${yourResult?.restartCount > 1 ? 's' : ''}` : '0 Restart'}
              </TextBtn>
            </NextBtn>
          </SectionContainer>
          <SectionContainer>
            <MainContainer
              gap={screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"}
            >
              <SectionContainer
                gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
              >
                {yourResult?.rating &&
                  Array.from({ length: yourResult?.rating }).map((item, index) => {
                    return (
                      <StarImg
                        src={appImages.star}
                      />
                    )
                  })
                }
              </SectionContainer>
              <SectionContainer
                gap={screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"}
                paddingleftcstm
              >
                <TextWrapper
                  fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
                  fontfamily={'poppins400'}
                  color={appColors.greyTextColor}
                  positionrelative={'relative'}
                >
                  {`A ${yourResult?.rating} star performance`}
                </TextWrapper>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={appImages.info}
                    onClick={() => setIsRatingModalVisible(true)}
                    style={{ position: 'absolute' }}
                  />
                </div>
              </SectionContainer>
              <PuzzleContainer>
                <TextWrapper
                  fontsize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth) * 100 + "vw"}
                  fontfamily={'poppins500'}
                  color={appColors.greyTextColor}
                >
                  Puzzle Difficulty:
                </TextWrapper>
                {puzzleDifficulty(challengeDifficulty)}
              </PuzzleContainer>
            </MainContainer>
          </SectionContainer>
          <SectionContainer>
            <MainContainer
              gap={screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"}
            >
              {/* <SectionContainer visibiltyhidden>
                <TextWrapper
                  fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
                  fontfamily={'poppins400'}
                  color={appColors.greyTextColor}
                >
                  Can you beat your friends?
                </TextWrapper>
              </SectionContainer>
              <SectionContainer visibiltyhidden>
                <NextBtn
                  variant="contained"
                  size="small"
                  height={
                    screenWidth > maximumTabletWidth
                      ? "50px"
                      : (50 / screenWidth) * 100 + "vw"
                  }
                  width={
                    screenWidth > maximumTabletWidth
                      ? "286px"
                      : (286 / screenWidth) * 100 + "vw"
                  }
                  backgroundcolor={appColors.whiteText}
                  border={'1px solid'}
                  borderColor={'#CACACA'}
                  borderStyle={'solid'}
                  boxshadow={'none'}
                >
                  <TextBtn
                    color={appColors.greyTextColor}
                    fontSize={
                      screenWidth > maximumTabletWidth
                        ? "16px"
                        : (16 / screenWidth) * 100 + "vw"
                    }
                    fontFamily={"poppins500"}
                  >
                    Share
                  </TextBtn>
                </NextBtn>
              </SectionContainer> */}
              <SectionContainer>
              <BottomBtnContainer>
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "280px"
                        : (280 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteText}
                    border={'1px solid'}
                    borderColor={'#CACACA'}
                    borderStyle={'solid'}
                    boxshadow={'none'}
                    onClick={() => {
                      dispatch(userEventsInitiate({ type: 'share' }))
                      // handOpenShareModal()
                      handleShare()
                    }}
                  >
                    <TextBtn
                      multiitems
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "16px"
                          : (16 / screenWidth) * 100 + "vw"
                      }
                      fontFamily={"poppins500"}
                    >
                      Share with friends
                    </TextBtn>
                    <ShareImg
                        src={appImages.share}
                      />
                  </NextBtn>
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteText}
                    border={'1px solid'}
                    borderColor={'#CACACA'}
                    borderStyle={'solid'}
                    boxshadow={'none'}
                    onClick={() => {
                      handleVisiblePerformanceScreen()
                    }}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      // fontSize={
                      //   screenWidth > maximumTabletWidth
                      //     ? "16px"
                      //     : (16 / screenWidth) * 100 + "vw"
                      // }
                      // fontFamily={"poppins500"}
                    >
                      <ShareImg
                        src={appImages.barsSvg}
                        paddingtop={screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"}
                      />
                    </TextBtn>
                  </NextBtn>
                </BottomBtnContainer>
              </SectionContainer>
              <SectionContainer>
                <img src={appImages.bot} />
              </SectionContainer>
              <SectionContainer>
                <TextWrapper
                  fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
                  fontfamily={'poppins400'}
                  color={appColors.greyTextColor}
                  cstmpadding={`${screenWidth > maximumTabletWidth ? "0 15px" : `0 ${(15 / screenWidth) * 100}vw`}`}
                  textalign={'center'}
                >
                  Change Bot can Change It in <ResultText>{botStepsCount || 0}</ResultText>! <br />
                </TextWrapper>
              </SectionContainer>
              <SectionContainer cstmpadding={screenWidth > maximumTabletWidth ? "0 0 15px 0" : `0 0 ${(15 / screenWidth) * 100}vh 0`}>
                <BottomBtnContainer>
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "170px"
                        : (170 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteText}
                    border={'1px solid'}
                    borderColor={'#CACACA'}
                    borderStyle={'solid'}
                    boxshadow={'none'}
                    onClick={() => {
                      handleCloseResultScreen()
                      dispatch(getWordInitiate());
                      // gaEventTrackerForTryAgain(`Try_Again_Clicks`, `try_again_clicks`, 'total_count')
                      dispatch(userEventsInitiate({ type: 'tryAgain' }))
                    }}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "16px"
                          : (16 / screenWidth) * 100 + "vw"
                      }
                      fontFamily={"poppins500"}
                    >
                      Try Again
                    </TextBtn>
                  </NextBtn>
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "170px"
                        : (170 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteText}
                    border={'1px solid'}
                    borderColor={'#CACACA'}
                    borderStyle={'solid'}
                    boxshadow={'none'}
                    onClick={() => {
                      handleVisibleBotSolutionScreen()
                      dispatch(userEventsInitiate({ type: 'viewSolution' }))
                      // dispatch(botStepsInitiate(payloadData))
                    }}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "16px"
                          : (16 / screenWidth) * 100 + "vw"
                      }
                      fontFamily={"poppins500"}
                    >
                      View Solution
                    </TextBtn>
                  </NextBtn>
                </BottomBtnContainer>
              </SectionContainer>
            </MainContainer>
          </SectionContainer>
        </MainContainer>
        {/* <img
            src={appImages.cross}
            height={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
            width={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
          /> */}
      </ContentWrapper>
    </MainWrapper>
  );

  return (
    <div>

      <React.Fragment key={'bottom'}>

        <Drawer
          anchor={isResultVisible ? 'bottom' : 'top'}
          // anchor={'bottom'}
          open={isResultVisible}
          onClose={() => {
            // toggleDrawer(anchor, false)
          }}
        >
          {list('bottom')}
        </Drawer>
      </React.Fragment>
      <ModalCommon
        isRatingModalVisible={isRatingModalVisible}
        handleCloseModal={handleCloseModal}
      />
      <Loader open={botStepsLoader} />
    </div>
  );
}

export default React.memo(Result)