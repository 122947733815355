// store.js
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import game from "./reducers/game";

import watcherSaga from "./saga/watcherSaga";

let sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, logger]
// const middleware = [sagaMiddleware];

const middlewares = [...middleware];

const reducers = {
    game
};

const store = configureStore({
  reducer: reducers,
  middleware: () => {
    return middlewares
  },
});

sagaMiddleware.run(watcherSaga);

export default store;
