import socketIOClient from 'socket.io-client';
import jsonData from '../config/config.json'
import { getCookieValue } from './index';

export const socket = socketIOClient(jsonData[process.env.NODE_ENV].socketUrl, {
    autoConnect: true,
    reconnection: true,
    transports: ['websocket'],
    upgrade: false,
    auth: {
        Authorization: `${getCookieValue()}`,
    },
    
});
