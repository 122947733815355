import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react'
import { AttemptLabel, AttemptScoreContainer, AttemptValContainer, AttemptValue, GameBtnContainer, GameContainer, GenerateWord, LeftOptionsFirst, LoadImg, MoveBtn, NameContainer, NextBtn, OptionsFirst, OptionsFirstImg, RightOptionsFirst, RightOptionsFirstImg, StatusContainer, StatusIconContainer, StatusMessage, Step1Bottom, Step1Top, Step2Bottom, StepContainer, StepContent, TextBtn, TopOptions, TopOptionsLeft, TopOptionsRight } from './styles'
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';
import CenterContent from './centerContent';
import { useNavigate } from 'react-router-dom';
import { completeOnboardingInitiate } from '../../redux/actions/game';
import { useDispatch } from 'react-redux';
import { useAnimation } from 'framer-motion';

let imgRotation = 0

const Game = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const headerContent = useAnimation()
  const initialWordContent = useAnimation()
  const initialWordContentText = useAnimation()
  const targetWordContent = useAnimation()
  const targetWordContentText = useAnimation()
  const actionBtn1 = useAnimation()
  const actionBtn2 = useAnimation()
  const actionBtn3 = useAnimation()
  const actionBtn4 = useAnimation()
  const logoImg = useAnimation()
  const attemptsList = useAnimation()
  const actionTitleMessageContent = useAnimation()

  const duration = 1
  const delay = 1

  const {
    action,
    word,
    wordLoader,
    wordCopyForUndo,
    finalWord,
    initialWord,
    handleActionClick,
    handleAddClick,
    handleRemoveEmptyAddInput,
    temporaryWordIndex,
    handleWordLocked,
    handleUndoWord,
    isWordLocked,
    attempts,
    handleRemoveClick,
    handleChangeClick,
    handleInitializeChangeAction,
    handleInitializeMixupAction,
    handleVisiblePerformanceScreen,
    activeWordIndex,
    validateResponse,
    handleCloseResultScreen,
    handleKeyPress,
    isKeyboardVisible,
    actionBtnColor1,
    setActionBtnColor1,
    actionBtnColor2,
    setActionBtnColor2,
    actionBtnColor3,
    setActionBtnColor3,
    actionBtnColor4,
    setActionBtnColor4,
    gameData,
    words,
    setTemporaryWord
  } = props

  const scrollRefForSteps = useRef(null)



  const animate = useCallback( async () => {
    

    await new Promise((resolve) => setTimeout(resolve, 1 * 1500));

    initialWordContent.mount()
    targetWordContent.mount()
    initialWordContentText.mount()
    targetWordContentText.mount()
    actionBtn1.mount()
    logoImg.mount()
    attemptsList.mount()
    actionTitleMessageContent.mount()

    await Promise.all([
      initialWordContent.start({
        y: 0,
        transition: { duration: duration, delay: delay },
      }),
      targetWordContent.start({
        y: 0,
        transition: { duration: duration, delay: delay },
      }),
      initialWordContentText.start({
        opacity: [1,0.6,0.3,0.2,0.1,0],
        transition: { duration: (duration/1.5), delay: (delay*1.1), ease: 'easeOut', },
      }),
      targetWordContentText.start({
        opacity: [1,0.6,0.3,0.2,0.1,0],
        transition: { duration: (duration/1.5), delay: (delay*1.1), ease: 'easeOut' },
      }),

      attemptsList.start({
        opacity: 1,
        transition: { delay: 2, ease: 'easeOut' },
      }),

      actionBtn1.start({
        transition: { duration: 0, delay: 5, },
      }),

      actionTitleMessageContent.start({
        opacity: 1,
        transition: { delay: 3.5, duration: 0.2, ease: 'easeOut' },
      }),

      logoImg.start({
        rotate: 360,
        transition: { duration: 1.5, delay: 6 },
      }),
      
      // add some delay before starting the background active animation
      await new Promise((resolve) => setTimeout(resolve, 1 * 4000)),
      setActionBtnColor1(appColors.greyBtnColor),
      await new Promise((resolve) => setTimeout(resolve, 1 * 200)),
      setActionBtnColor1(appColors.whiteColor),
      setActionBtnColor2(appColors.greyBtnColor),
      await new Promise((resolve) => setTimeout(resolve, 1 * 200)),
      setActionBtnColor2(appColors.whiteColor),
      setActionBtnColor3(appColors.greyBtnColor),
      await new Promise((resolve) => setTimeout(resolve, 1 * 200)),
      setActionBtnColor3(appColors.whiteColor),
      setActionBtnColor4(appColors.greyBtnColor),
      await new Promise((resolve) => setTimeout(resolve, 1 * 200)),
      setActionBtnColor4(appColors.whiteColor),

    ])
  }, [words])

  const noAnimate = useCallback( async () => {
    attemptsList.mount()
    await Promise.all([
      attemptsList.start({
        opacity: 1,
        transition: { delay: 0, ease: 'easeOut' },
      })
    ])
  }, [])


  useEffect(() => {
    if (gameData == null) {
      if (!wordLoader && words) {
        animate()
      }
    } else {
      // noAnimate()
    }

    return () => {
      headerContent.stop();
      initialWordContent.stop();
      attemptsList.stop()
    };
  }, [wordLoader, words])

  const animateLogoImg = useCallback( async () => {
    logoImg.mount()

    // await logoImg.start({
    //   rotate: -360,
    //   transition: {
    //     duration: 0,  // Adjust duration to control speed
    //   },
    // });

    await logoImg.start({
      rotate: imgRotation + 360,
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 3,  // Adjust duration to control speed
        ease: 'linear',  // Use 'linear' for smooth continuous rotation
      },
    });
    logoImg.stop()
  }, [])

  useEffect(() => {
    if (isWordLocked && validateResponse?.message !== "Invalid Word. Try Again") {
      animateLogoImg()
      attemptsList.mount()
      attemptsList.start({
        opacity: 1,
        transition: { delay: 0, ease: 'easeOut' },
      })
      actionTitleMessageContent.mount()
      actionTitleMessageContent.start({
        opacity: 1,
      })
    } else {
      actionTitleMessageContent.mount()
      actionTitleMessageContent.start({
        opacity: 1,
        
      })
      // logoImg.stop()
      attemptsList.stop()
    }

    return () => {
      logoImg.stop()
    }

  }, [isWordLocked])

 

  useEffect(() => {
    if (scrollRefForSteps && scrollRefForSteps?.current?.scrollHeight) {
      scrollRefForSteps.current.scrollTop = scrollRefForSteps.current.scrollHeight || 0;
    }
  }, [isWordLocked])


  return (
    <GameContainer
      initial={{ opacity: 0}}
      animate={words && { opacity: [0,0.1,0.3,0.6,1]}}
      transition={{ duration: 1, ease: 'easeInOut' }}
    >
      <StepContent aligncenter={"center"} zindex>
        <TopOptions
          initial={{ opacity: gameData == null ? 0 : 1 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 6.5 }}
          cstmwidth={screenWidth > maximumTabletWidth ? "340px" : (340 / screenWidth * 100) + "vw"}
        >
          <TopOptionsLeft>
            <LeftOptionsFirst>
              <OptionsFirstImg
                initial={{ rotate: 0 }}
                animate={logoImg}
                onAnimationComplete={cheeck => imgRotation = cheeck.rotate}
                src={appImages.loadGrey}
              />
            </LeftOptionsFirst>
            {/* <LeftOptionsFirst>
            <img
            style={{
              height: '25px',
              width: '25px'
            }}
              // height={'25px'}
              // width={'25px'}
              // initial={{ rotate: 0 }}
              // animate={logoImg}
              // onAnimationComplete={cheeck => imgRotation = cheeck.rotate}
              src={appImages.logoBorder}
            />
              <OptionsFirstImg
                initial={{ rotate: 0 }}
                animate={logoImg}
                onAnimationComplete={cheeck => imgRotation = cheeck.rotate}
                src={appImages.loadGrey}
              />
            </LeftOptionsFirst> */}
            <NameContainer>CHANGE IT</NameContainer>
          </TopOptionsLeft>
          <TopOptionsRight>
            {/* <RightOptionsFirst
              height={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "30px" : (30 / screenWidth * 100) + "vw"}
            >
              <RightOptionsFirstImg
                src={appImages.users}
              />
            </RightOptionsFirst> */}
            <RightOptionsFirst
              height={screenWidth > maximumTabletWidth ? "25px" : (25 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "25px" : (25 / screenWidth * 100) + "vw"}
            >
              <RightOptionsFirstImg
                src={appImages.queestion}
                onClick={() => {
                  dispatch(completeOnboardingInitiate({ isInitiate: false, checkGameData: true }))
                }}
              />
            </RightOptionsFirst>
            <RightOptionsFirst
              height={screenWidth > maximumTabletWidth ? "25px" : (25 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "25px" : (25 / screenWidth * 100) + "vw"}
            >
              <RightOptionsFirstImg
                src={appImages.bars}
                onClick={() => handleVisiblePerformanceScreen()}
              />
            </RightOptionsFirst>
          </TopOptionsRight>
        </TopOptions>
        <StepContent
          gap={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vh"}
          positionrelative
        >
          <MoveBtn
            initial={{ y: 50, opacity: gameData ? 0 : 1 }}
            animate={initialWordContentText}
            positionabsolute
          >

          <TextBtn
            color={appColors.greyTextColor}
            fontSize={screenWidth > maximumTabletWidth ? "22px" : (22 / screenWidth * 100) + "vw"}
          >
            Starting Word
          </TextBtn>
          </MoveBtn>
          <Step1Top
          // gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >
            <MoveBtn
              initial={{ y: gameData ? 0 : 100 }}
              animate={initialWordContent}
              gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
            >
              {
                initialWord
                &&
                initialWord.length > 0
                &&
                initialWord.map((item, index) => {
                  let wordDimension =
                  initialWord?.length >= 7 ? 40 : initialWord?.length >= 6 ? 50 : 60;
                  return (
                    <NextBtn
                      variant="contained"
                      size="small"
                      height={
                        screenWidth > maximumTabletWidth
                          ? `${wordDimension}px`
                          : (wordDimension / screenWidth) * 100 + "vw"
                      }
                      width={
                        screenWidth > maximumTabletWidth
                          ? `${wordDimension}px`
                          : (wordDimension / screenWidth) * 100 + "vw"
                      }
                      backgroundcolor={appColors.whiteColor}
                      boxshadow
                      cursor={'default'}
                    >
                      <TextBtn
                        color={appColors.greyTextColor}
                        fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
                      >
                        {item || ""}
                      </TextBtn>
                    </NextBtn>
                  )
                })
              }
            </MoveBtn>
          </Step1Top>
        </StepContent>
        {attempts?.length > 0 &&
          <AttemptScoreContainer
            initial={{ opacity: gameData == null ? 0 : 1 }}
            animate={attemptsList}
            cstmHeight={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "340px" : (340 / screenWidth * 100) + "vw"}
            padding={screenWidth > maximumTabletWidth ? "10px 0" : (10 / screenWidth * 100) + "vh 0"}
            ref={scrollRefForSteps}
          >
            {attempts?.map((item, index) => {

              return (
                <AttemptValContainer key={index}>
                  <AttemptLabel fontfamily={'poppins500'}>
                    {item || ""}
                  </AttemptLabel>
                  <AttemptValue>
                    {index + 1}
                  </AttemptValue>
                </AttemptValContainer>
              )
            })
            }
          </AttemptScoreContainer>
        }

        {words && <CenterContent
          setTemporaryWord={setTemporaryWord}
          word={word}
          wordCopyForUndo={wordCopyForUndo}
          action={action}
          handleActionClick={handleActionClick}
          handleAddClick={handleAddClick}
          handleRemoveEmptyAddInput={handleRemoveEmptyAddInput}
          temporaryWordIndex={temporaryWordIndex}
          handleWordLocked={handleWordLocked}
          handleUndoWord={handleUndoWord}
          attempts={attempts}
          isWordLocked={isWordLocked}
          handleRemoveClick={handleRemoveClick}
          handleChangeClick={handleChangeClick}
          handleInitializeChangeAction={handleInitializeChangeAction}
          handleInitializeMixupAction={handleInitializeMixupAction}
          activeWordIndex={activeWordIndex}
          validateResponse={validateResponse}
          actionBtn1={actionBtn1}
          actionBtn2={actionBtn2}
          actionBtn3={actionBtn3}
          actionBtn4={actionBtn4}
          actionBtnColor1={actionBtnColor1}
          setActionBtnColor1={setActionBtnColor1}
          actionBtnColor2={actionBtnColor2}
          setActionBtnColor2={setActionBtnColor2}
          actionBtnColor3={actionBtnColor3}
          setActionBtnColor3={setActionBtnColor3}
          actionBtnColor4={actionBtnColor4}
          setActionBtnColor4={setActionBtnColor4}
          gameData={gameData}
          actionTitleMessageContent={actionTitleMessageContent}
        />}

      </StepContent>

      {isWordLocked &&
        <StatusContainer
          gap={screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vh"}

        >
          <StatusIconContainer
            bgcolor={validateResponse?.message == "Change Successful!" ? appColors.successBtnColor : appColors.errorBtnColor}
          >
            {
              validateResponse?.message == "Invalid Word. Try Again"
                ?
                <img
                  alt=""
                  style={{
                    height:
                      screenWidth > maximumTabletWidth
                        ? "30px"
                        : (30 / screenWidth) * 100 + "vw",

                    width:
                      screenWidth > maximumTabletWidth
                        ? "30px"
                        : (30 / screenWidth) * 100 + "vw",
                  }}
                  src={appImages.crossWhite}
                />
                :
                <img
                  alt=""
                  style={{
                    height:
                      screenWidth > maximumTabletWidth
                        ? "23.84px"
                        : (23.84 / screenWidth) * 100 + "vw",

                    width:
                      screenWidth > maximumTabletWidth
                        ? "23.88px"
                        : (23.88 / screenWidth) * 100 + "vw",
                  }}
                  src={appImages.tick}
                />
            }
          </StatusIconContainer>
          <StatusMessage>
            {validateResponse?.message || ""}
          </StatusMessage>
        </StatusContainer>
      }
      <StepContent
          gap={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vh"}
          positionrelative
        >
      <Step1Top>
        <MoveBtn
          initial={{ y: gameData ? 0 : -100 }}
          animate={targetWordContent}
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        >
          {
            finalWord
            &&
            finalWord.length > 0
            &&
            finalWord.map((item, index) => {
              let wordDimension =
              finalWord?.length >= 7 ? 40 : finalWord?.length >= 6 ? 50 : 60;
              return (
                <NextBtn
                  key={index}
                  variant="contained"
                  size="small"
                  height={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  width={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  backgroundcolor={appColors.whiteColor}
                  boxshadow
                  cursor={(!isKeyboardVisible || action[0]["mixup"]["isActive"]) && 'default'}
                  onClick={() => {
                    if (isKeyboardVisible && !action[0]["mixup"]["isActive"]) {
                      handleKeyPress(item)
                    }
                  }}
                >
                  <TextBtn
                    color={appColors.greyTextColor}
                    fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
                  >
                    {item || ''}
                  </TextBtn>
                </NextBtn>
              )
            })
          }
        </MoveBtn>
      </Step1Top>
      <MoveBtn
        initial={{ y: -20, opacity: gameData ? 0 : 1 }}
        animate={targetWordContentText}
        positionabsolute
      >

        <TextBtn
          color={appColors.greyTextColor}
          fontSize={screenWidth > maximumTabletWidth ? "22px" : (22 / screenWidth * 100) + "vw"}
        >
          Target Word
        </TextBtn>
      </MoveBtn>
      </StepContent>
    </GameContainer>
  )
}

export default React.memo(Game);
