import styled, { css } from 'styled-components'
import { Box, Button, Modal } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';

export const ModalContainer = styled(Modal)`
    .MuiBackdrop-root.MuiModal-backdrop {
        // opacity: 0.5!important;
        background-color: rgba(171, 171, 171, 0.5)!important;
    }
`

export const BoxContainer = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px!important;
    background-color: ${appColors.modalBackgroundColor};
    padding: ${screenWidth > maximumTabletWidth ? "10px" : (10 /screenWidth * 100)+"vw"};
    outline: none!important;
    @media (max-width: ${maximumTabletWidth}px) {
        width: 85vw!important;
    }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  ${({ gap }) => css`
    gap: ${gap};
  `}  
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}  
`

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => gap && css`
    gap: ${gap};
  `} 
  ${({ postionrelative }) => postionrelative && css`
    position: ${postionrelative};
  `} 
  ${({ textalign }) => textalign && css`
    align-items: ${textalign};
  `} 
`

export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}
  ${({ textalign }) => textalign && css`
    text-align: ${textalign};
  `}
`

export const CrossImg = styled.img`
  position: absolute;
  right: 0;
  padding-right: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  height: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  cursor: pointer;
`

export const NextBtn = styled(motion(Button))`
  text-transform: none!important;
  min-width: auto!important;
  border-radius: 8px!important;
  padding: 0!important;
  box-shadow: none!important;

  ${({ boxshadow}) => boxshadow && css`
    box-shadow: -3px 3px 15px 1px #5E5E5EB2!important;
  `}
  ${({ boxshadowactionbtn}) => boxshadowactionbtn && css`
    box-shadow: 0px 6px 10px 0px #5E5E5EB2!important;
  `}
  ${({ borderradius }) => borderradius && css`
  border-radius: ${borderradius}!important;
  `}
  ${({ height, width, backgroundcolor, initial }) => css`
    height: ${height};
    width: ${width};
    background: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) => border && css`
    border: ${border}!important;
    border-color: ${borderColor}!important;
    border-style: ${borderStyle && borderStyle}!important;
  `}

  ${({ boxshadow }) => boxshadow && css`
    box-shadow: ${boxshadow}!important;
  `}
  ${({ cursor }) => cursor && css`
    cursor: ${cursor}!important;
  `}

  ${({ positionabsolute }) => positionabsolute && css`
    position: absolute!important;
    
  `}
`

export const TextBtn = styled.span`
  font-family: poppins500;
  ${({ fontFamily }) => fontFamily && css`
  font-family: ${fontFamily}!important;
  `}
  ${({ color, fontSize }) => css`
    color: ${color};
    font-size: ${fontSize};
  `}
  ${({ paddingtopcstm }) => paddingtopcstm && css`
    padding-top: ${paddingtopcstm};
  `}  
`