import React from 'react'

import { BoxContainer, CrossImg, MainContainer, ModalContainer, SectionContainer, TextWrapper } from './styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';
import { NextBtn } from './styles';
import { TextBtn } from './styles';
import { restartClickInitiate, userEventsInitiate, viewSolutionClickInitiate } from '../../redux/actions/game';
import { useDispatch, useSelector } from 'react-redux';



const ModalForUtilities = (props) => {

  const {
    isModalVisible,
    handleCloseModal,
    title,
    utilsModalData,
    handleSubmitClick,
    handleVisibleBotSolutionScreen,
    wordId
  } = props

  const reducerStates = useSelector((state) => state.game)
  const {
    restartCount
  } = reducerStates

  const dispatch = useDispatch()

  const content = (
    <>
    <SectionContainer>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "40px" : (40 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins700'}
              color={appColors.dangerColor}
              textalign={'center'}
            >
              {/* {restartCount ? `+${restartCount}` : "0"} */}
              {"+1"}
            </TextWrapper>
          </SectionContainer>
          <SectionContainer>
          <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
              textalign={'center'}
            >
              Are you sure you want to restart?
            </TextWrapper>
            </SectionContainer>
            <SectionContainer>
          <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
              textalign={'center'}
            >
              If you restart, a penalty point is added to your final score.
            </TextWrapper>
            </SectionContainer>
          </>
  )

  const contentViewSolution = (
    <>
      <SectionContainer>
            <img src={appImages.bot} />
          </SectionContainer>
          <SectionContainer>
          <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
              textalign={'center'}
            >
              Are you sure you want to give up and view Change Bot’s optimum solution?
            </TextWrapper>
            </SectionContainer>
            <SectionContainer>
          <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
              textalign={'center'}
            >
              If you do, you will receive no star rating for the challenge.
            </TextWrapper>
            </SectionContainer>
          </>
  )

  return (
    <ModalContainer
      open={isModalVisible}
      onClose={() => null}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      
      <BoxContainer>
        {console.log("this is coming")}
        <MainContainer cstmpadding={screenWidth > maximumTabletWidth ? "10px 0" : (10 / screenWidth) * 100 + "vh 0"}>
          <SectionContainer postionrelative={'relative'} textalign={'center'}>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins700'}
              color={appColors.greyTextColor}
            >
              {title || ""}
            </TextWrapper>
            <CrossImg
              onClick={() => {
                handleCloseModal()
                if (title == 'Restart') {
                  dispatch(userEventsInitiate({ type: 'restartFalse' }))
                } else {
                  dispatch(userEventsInitiate({ type: 'viewFalse' }))
                }
              }}
              src={appImages.cross}
            />
          </SectionContainer>
          {
            utilsModalData == 'restart' ?
            content :
            contentViewSolution
          }
          <SectionContainer
            gap={"15px"}
          >
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "45px" : (45 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "150px" : (150 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              cursor={'default'}
              onClick={() => {
                handleCloseModal()
                if (title == 'Restart') {
                  dispatch(userEventsInitiate({ type: 'restartFalse' }))
                } else {
                  dispatch(userEventsInitiate({ type: 'viewFalse' }))
                }
              }}
            >
                  <TextBtn
                    color={appColors.greyTextColor}
                    fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
                  >
                    {title == 'Restart' ? 'No Thanks' : 'Keep Trying'}
                  </TextBtn>
              
            </NextBtn>
            <NextBtn
               variant="contained"
               size="small"
               height={screenWidth > maximumTabletWidth ? "45px" : (45 / screenWidth * 100) + "vw"}
               width={screenWidth > maximumTabletWidth ? "150px" : (150 / screenWidth * 100) + "vw"}
               
               backgroundcolor={appColors.successDarkGreen}
               cursor={'default'}
               onClick={() => {
                 if (title == 'Restart') {
                  dispatch(userEventsInitiate({ type: 'restartSuccess' }))
                  dispatch(restartClickInitiate({id: wordId}))
                  handleSubmitClick()
                  handleCloseModal()
                 } else {
                  dispatch(userEventsInitiate({ type: 'viewSuccess' }))
                  handleSubmitClick()
                  handleVisibleBotSolutionScreen()
                  dispatch(viewSolutionClickInitiate({id: wordId}))
                  handleCloseModal()
                 }
               }}
            >
            <TextBtn
                     color={appColors.whiteText}
                    fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
                  >
                    {title == 'Restart' ? 'Restart' : 'View Solution'}
                  </TextBtn>
            </NextBtn>
          </SectionContainer>
        </MainContainer>

      </BoxContainer>
    </ModalContainer>
  )
}

export default ModalForUtilities
