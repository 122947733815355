import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { Routes, Route, Navigate, useNavigate, } from "react-router-dom";
import CompletedGame from './screens/finalSolution/index'
import "./App.css";
import Onboarding from "./screens/onboarding";
import GamePlay from "./screens/gamePlay";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { getProfileInitiate, getTimeInitiate, isUserExistInitiate, updateGamePlayStates } from "./redux/actions/game";
import { getCookieValue, navigateCommon, toastCommon } from "./utils";
import Loader from "./components/loader";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

function App() {
  const theme = createTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const gaEventTrackerForTotalUsers = useAnalyticsEventTracker('Total Users');

  const reducerStates = useSelector(state => state.game)
  const {
    isUserExistLoader,
    isUserExistSuccess,
    isUserExist,
    completedOnboarding,
    selectedFilter
  } = reducerStates

  
  const [toastConfig, setToastConfig] = React.useState(null);
  // const check = window.matchMedia('(orientation:landscape)').matches;
  // alert("this is check orientation = "+ window.innerWidth)
  // console.log("this is check orientation = ", check)


  // const socketConnect = async () => {
  //   const tempUserId = ""
  //   const accessToken = ""
  //   if (tempUserId && accessToken) {
  //     socket.emit('login', { userId: tempUserId, accessToken: accessToken });
  //   }
  //   // isSocketLoggedInFromKilled = false
  // }
 

  useLayoutEffect(() => {
    navigateCommon.set(navigate);
    dispatch(isUserExistInitiate())
    dispatch(getTimeInitiate())
    dispatch(updateGamePlayStates(false, 'yourResultSuccess'))
    ReactGA.initialize('G-LVM1E28DQC')
  }, [])

  const ProtectedRoutes = ({ component, navigate }) => {
    let showresult=JSON.parse(localStorage.getItem("gameCompleted")); 
    return showresult ? component : navigate
  }

  const PublicdRoutes = useCallback(({ component, navigate }) => {
    let showresult=JSON.parse(localStorage.getItem("gameCompleted"));
    return !showresult ? component : navigate
  }, [])

  useEffect(() => {
    if (isUserExist) {
      dispatch(getProfileInitiate({ filter: selectedFilter }))
    }
  }, [selectedFilter])

  useEffect(() => {
    if(!isUserExistLoader && !isUserExist && isUserExistSuccess) {
      gaEventTrackerForTotalUsers('Total_Users', "count", 'total__users_count')
    }
  }, [isUserExistLoader])

  return (
    <ThemeProvider theme={theme}>
      {!isUserExistSuccess ?
        <>
        <Loader />
        </>
        :
        <Routes>
          <Route path="/" element={(isUserExist && completedOnboarding) ? <Navigate to="/game" /> : <Onboarding />} />
          {/* <Route path="/game" element={(isUserExist && completedOnboarding) ? <PublicdRoutes component={<GamePlay />} navigate={<Navigate to="/gameCompleted" />} /> : <Navigate to="/" />} />
          <Route path="/gameCompleted" element={<ProtectedRoutes component={<CompletedGame />} navigate={<Navigate to="/game" />} />} /> */}
          <Route path="/game" element={(isUserExist && completedOnboarding) ? <GamePlay /> : <Navigate to="/" />} />
        </Routes>
      }
    </ThemeProvider>
  );
}

export default App;
