export const appColors = {
    headingTextColor: '#363379',
    successDarkGreen: '#4EB546',
    successBtnColor: '#4BB543',
    errorBtnColor: '#DE2418',
    greyBtnColor: '#E5E5F0',
    whiteColor: '#FFFFFF',
    greyTextColor: '#5E5E5E',
    step1Color: '#DE808B',
    step2Color: '#7FB7D6',
    step3Color: '#BF80DD',
    step4Color: '#DAB67F',
    step5Color: '#98DD80',
    step6Color: '#809FDC',
    step7Color: '#DAD67F',
    borderColor: '#000000',
    whiteText: '#FAFAFA',
    themeColor3: '#6D5C74',
    actionIconBgColor: '#E9E1E1',
    blackColor: '#000000',
    moderateColor: '#F1C527',
    modalBackgroundColor: '#F7F7F7',
    cardBorderColor: '#FAFAFA',
    cardLabelColor: '#ABABAB',
    filterPopColor: '#D9D9D9',
    veryHardColor: '#F10707',
    hardColor: '#F16427',
    dangerColor:'#E11B1B'
}