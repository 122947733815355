import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  AttemptLabel,
  AttemptScoreContainer,
  AttemptValContainer,
  AttemptValue,
  BannerWrapper,
  BottomCWrapper,
  ContentWrapper,
  GameContainer,
  LeftOptionsFirst,
  NameContainer,
  NextBtn,
  OnboardingWrapper,
  OptionsFirstImg,
  RightOptionsFirst,
  RightOptionsFirstImg,
  Step1Top,
  StepContent,
  TextBtn,
  TopOptions,
  TopOptionsLeft,
  TopOptionsRight,
  BottomBtnContainer,
  SectionContainer,
  TextWrapper,
  ResultText,
} from "./styles";
import { maximumTabletWidth, screenWidth } from "../../utils";
import { appColors } from "../../themes/colorConst";
import { appImages } from "../../themes/imageConstant";
import { useNavigate } from "react-router-dom";
import BotSolutions from "../botSolutions";
import YourPerformance from "../yourPerformance";
import { useDispatch, useSelector } from "react-redux";
import { completeOnboardingInitiate, getProfileInitiate, getWordInitiate, updateGamePlayStates, userEventsInitiate } from "../../redux/actions/game";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import ModalShare from "../../components/modalShare";

// import CenterContent from './centerContent';

const CompletedGame = (props) => {

  const gaEventTrackerForTryAgain = useAnalyticsEventTracker('Try Again');
  const gaEventTrackerForViewSolution = useAnalyticsEventTracker('View Solution');

  const {
    finalWord,
    initialWord,
    attempts,
    isPerformanceVisible,
    handleVisiblePerformanceScreen,
    handleClosePerformanceScreen,
    handleChangeFilterValue,
    selectedFilter,
    getFirstGame,
    gameCompleted
  } = props

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reducerStates = useSelector(state => state.game)
  const {
    profileDataLoader,
    profileDataSuccess,
    profileData,
    validateResponse,
    botStepsCount,
    time
  } = reducerStates

  // Bot Solution screen States
  const [isBotSolutionVisible, setIsBotSolutionVisible] = React.useState(false)
  // Performance screen States
  const [countdown, setCountdown] = useState(0);

  const countdownRef = useRef(countdown);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const [timeState, setTimeState] = useState(time || 0);

  useEffect(() => {
    if (timeState > 0) {
      const intervalId = setInterval(() => {
        setTimeState((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [timeState]);
  // console.log("formatTime(time) = ", formatTime(timeState))

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
        metaThemeColor.setAttribute('content', `#D2AAE470`);
    }
  }, []);

  useLayoutEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(24, 0, 0, 0); // Set to midnight in local timezone

      const remainingTime = midnight - now;
      countdownRef.current = remainingTime;

      if (Math.floor(remainingTime / 1000) !== Math.floor(countdown / 1000)) {
        setCountdown(remainingTime);
      }
    };

    const interval = setInterval(updateCountdown, 1000); // Update every second

    updateCountdown(); // Initial call to set the initial state

    return () => clearInterval(interval);
  }, [countdown]);

  const scrollRefForSteps = useRef(null)

  useEffect(() => {
    if (scrollRefForSteps && scrollRefForSteps?.current?.scrollHeight) {
      scrollRefForSteps.current.scrollTop = scrollRefForSteps.current.scrollHeight || 0;
    }
  }, [])

  // Convert remaining time to hours, minutes, seconds
  const hours = Math.floor((countdown / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((countdown / (1000 * 60)) % 60);
  const seconds = Math.floor((countdown / 1000) % 60);

  // Bot Solution Features
  const handleVisibleBotSolutionScreen = () => {
    gaEventTrackerForViewSolution(`View_Solution_Clicks`, 'view_solution_clicks', 'total_count')
    setIsBotSolutionVisible(!isBotSolutionVisible);
  };


  return (
    <OnboardingWrapper>
      <ContentWrapper id={"contentContainer"}>
        <BannerWrapper
          background={appImages.gameBanner3}
          backgroundposition={screenWidth > 360 ? "bottom" : "top"}
          cstmheight={"45%"}
        >
          <GameContainer>
            <StepContent aligncenter={"center"}>
              <TopOptions
                cstmwidth={
                  screenWidth > maximumTabletWidth
                    ? "340px"
                    : (340 / screenWidth) * 100 + "vw"
                }
              >
                <TopOptionsLeft>
                  <LeftOptionsFirst>
                    <OptionsFirstImg src={appImages.loadGrey} />
                  </LeftOptionsFirst>
                  <NameContainer>CHANGE IT</NameContainer>
                </TopOptionsLeft>
                <TopOptionsRight>
                  {/* <RightOptionsFirst
                    height={
                      screenWidth > maximumTabletWidth
                        ? "20px"
                        : (20 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "30px"
                        : (30 / screenWidth) * 100 + "vw"
                    }
                  >
                    <RightOptionsFirstImg src={appImages.users} />
                  </RightOptionsFirst> */}
                  <RightOptionsFirst
                    height={
                      screenWidth > maximumTabletWidth
                        ? "25px"
                        : (25 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "25px"
                        : (25 / screenWidth) * 100 + "vw"
                    }
                  >
                    <RightOptionsFirstImg onClick={() => {
                      dispatch(completeOnboardingInitiate({ isInitiate: false }))
                    }} src={appImages.queestion} />
                  </RightOptionsFirst>
                  <RightOptionsFirst
                    height={
                      screenWidth > maximumTabletWidth
                        ? "25px"
                        : (25 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "25px"
                        : (25 / screenWidth) * 100 + "vw"
                    }
                  >
                    <RightOptionsFirstImg
                      onClick={() => {
                        handleVisiblePerformanceScreen()
                      }}
                      src={appImages.bars} />
                  </RightOptionsFirst>
                </TopOptionsRight>
              </TopOptions>
              <StepContent
                gap={
                  screenWidth > maximumTabletWidth
                    ? "20px"
                    : (20 / screenWidth) * 100 + "vh"
                }
              >
                <Step1Top
                  gap={
                    screenWidth > maximumTabletWidth
                      ? "10px"
                      : (10 / screenWidth) * 100 + "vw"
                  }
                >
                  {
                    initialWord
                    &&
                    initialWord.length > 0
                    &&
                    initialWord.map((item, index) => {
                      return (
                        <NextBtn
                        key={index}
                          variant="contained"
                          size="small"
                          height={
                            screenWidth > maximumTabletWidth
                              ? "60px"
                              : (60 / screenWidth) * 100 + "vw"
                          }
                          width={
                            screenWidth > maximumTabletWidth
                              ? "60px"
                              : (60 / screenWidth) * 100 + "vw"
                          }
                          backgroundcolor={appColors.whiteColor}
                          cursor={'default'}
                        >
                          <TextBtn
                            color={appColors.greyTextColor}
                            fontSize={
                              screenWidth > maximumTabletWidth
                                ? "36px"
                                : (36 / screenWidth) * 100 + "vw"
                            }
                          >
                            {item || ""}
                          </TextBtn>
                        </NextBtn>
                      )
                    })
                  }
                </Step1Top>
              </StepContent>
            </StepContent>
            <div>
              {validateResponse?.data?.attemptsArray?.length > 0  &&


                <AttemptScoreContainer
                cstmHeight={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
                  width={
                    screenWidth > maximumTabletWidth
                      ? "340px"
                      : (340 / screenWidth) * 100 + "vw"
                  }
                  padding={
                    screenWidth > maximumTabletWidth
                      ? "10px 0"
                      : (10 / screenWidth) * 100 + "vh 0"
                  }
                  ref={scrollRefForSteps}
                >
                  {validateResponse?.data?.attemptsArray?.map((item, index) => {
                    return (
                      <AttemptValContainer>
                        <AttemptLabel fontfamily={"poppins500"}>
                          {item.toUpperCase() || ""}
                        </AttemptLabel>
                        <AttemptValue>{index + 1}</AttemptValue>
                      </AttemptValContainer>
                    )
                  })
                  }
                </AttemptScoreContainer>

              }
              {
                localStorage.getItem("isGamePlayed") == "true" && getFirstGame?.length > 0 && !validateResponse &&
                <AttemptScoreContainer
                cstmHeight={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
                  width={
                    screenWidth > maximumTabletWidth
                      ? "340px"
                      : (340 / screenWidth) * 100 + "vw"
                  }
                  padding={
                    screenWidth > maximumTabletWidth
                      ? "10px 0"
                      : (10 / screenWidth) * 100 + "vh 0"
                  }
                  ref={scrollRefForSteps}
                >
                  {getFirstGame?.map((item, index) => {
                    return (
                      <AttemptValContainer>
                        <AttemptLabel fontfamily={"poppins500"}>
                          {item || "df"}
                        </AttemptLabel>
                        <AttemptValue>{index + 1}</AttemptValue>
                      </AttemptValContainer>
                    )
                  })
                  }
                </AttemptScoreContainer>
              }

            </div>
            <Step1Top
              gap={
                screenWidth > maximumTabletWidth
                  ? "10px"
                  : (10 / screenWidth) * 100 + "vw"
              }
              paddingbottom={screenWidth > maximumTabletWidth
                ? "15px"
                : (15 / screenWidth) * 100 + "vw"}
            >
              {
                finalWord
                &&
                finalWord.length > 0
                &&
                finalWord.map((item, index) => {
                  return (
                    <NextBtn
                      key={index}
                      variant="contained"
                      size="small"
                      height={
                        screenWidth > maximumTabletWidth
                          ? "60px"
                          : (60 / screenWidth) * 100 + "vw"
                      }
                      width={
                        screenWidth > maximumTabletWidth
                          ? "60px"
                          : (60 / screenWidth) * 100 + "vw"
                      }
                      backgroundcolor={appColors.whiteColor}
                      cursor={'default'}
                    >
                      <TextBtn
                        color={appColors.greyTextColor}
                        fontSize={
                          screenWidth > maximumTabletWidth
                            ? "36px"
                            : (36 / screenWidth) * 100 + "vw"
                        }
                      >
                        {item}
                      </TextBtn>
                    </NextBtn>
                  )
                })
              }

            </Step1Top>
          </GameContainer>
          <BotSolutions
            isResultVisible={isBotSolutionVisible}
            handleVisibleResultScreen={handleVisibleBotSolutionScreen}
            handleCloseBotScreen={() => setIsBotSolutionVisible(false)}
            finalWord={finalWord}
            initialWord={initialWord}
            botStepsCount={botStepsCount}
          />
          <YourPerformance
            isPerformanceVisible={isPerformanceVisible}
            handleVisiblePerformanceScreen={handleVisiblePerformanceScreen}
            profileData={profileData}
            handleClosePerformanceScreen={handleClosePerformanceScreen}
            handleChangeFilterValue={handleChangeFilterValue}
            selectedFilter={selectedFilter}
          />
        </BannerWrapper>
        <BottomCWrapper cstmheight={"55%"}>
          <SectionContainer>
            <img src={appImages.bot} />
          </SectionContainer>
          <SectionContainer>
            <TextWrapper
              fontsize={
                screenWidth > maximumTabletWidth
                  ? "20px"
                  : (20 / screenWidth) * 100 + "vw"
              }
              fontfamily={"poppins400"}
              color={appColors.greyTextColor}
              cstmpadding={`${screenWidth > maximumTabletWidth
                ? "0 15px"
                : `0 ${(15 / screenWidth) * 100}vw`
                }`}
              textalign={"center"}
            >
              Change Bot can Change It in <ResultText>{botStepsCount || 0}</ResultText>! <br />Can you?
            </TextWrapper>
          </SectionContainer>
          <SectionContainer>
            <BottomBtnContainer>
              <NextBtn
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "50px"
                    : (50 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "170px"
                    : (170 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteText}
                border={"1px solid"}
                borderColor={"#CACACA"}
                borderStyle={"solid"}
                boxshadow={"none"}
                onClick={() => {
                  // localStorage.setItem("gameCompleted",false)
                  dispatch(updateGamePlayStates(false, 'gameCompleted'))
                  dispatch(updateGamePlayStates(false, "viewSolutionClickSuccess"));
                  dispatch(updateGamePlayStates(false, "viewSolution"));
                  navigate('/game')
                  dispatch(getWordInitiate());
                  // gaEventTrackerForTryAgain(`Try_Again_Clicks`, `try_again_clicks`, 'total_count')
                  dispatch(userEventsInitiate({type: 'tryAgain' }))
                }}
              >
                <TextBtn

                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "16px"
                      : (16 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins500"}
                >
                  Try Again
                </TextBtn>
              </NextBtn>
              <NextBtn
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "50px"
                    : (50 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "170px"
                    : (170 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteText}
                border={"1px solid"}
                borderColor={"#CACACA"}
                borderStyle={"solid"}
                boxshadow={"none"}
                onClick={() => handleVisibleBotSolutionScreen()}
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "16px"
                      : (16 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins500"}
                >
                  View Solution
                </TextBtn>
              </NextBtn>
            </BottomBtnContainer>
          </SectionContainer>
          <TextWrapper
            fontsize={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            fontfamily={"poppins400"}
            color={appColors.greyTextColor}
            cstmpadding={`${screenWidth > maximumTabletWidth
              ? "0 15px"
              : `0 ${(15 / screenWidth) * 100}vw`
              }`}
            textalign={"center"}
          >
            Next Change It
          </TextWrapper>
          <TextWrapper
            fontsize={
              screenWidth > maximumTabletWidth
                ? "35px"
                : (35 / screenWidth) * 100 + "vw"
            }
            fontfamily={"poppins600"}
            color={appColors.blackColor}
            cstmpadding={`${screenWidth > maximumTabletWidth
              ? "0 15px"
              : `0 ${(15 / screenWidth) * 100}vw`
              }`}
            textalign={"center"}
            marginTop={"-17px"}
          >
            {/* <span>{hours.toString().padStart(2, '0')}:</span>
            <span>{minutes.toString().padStart(2, '0')}:</span>
            <span>{seconds.toString().padStart(2, '0')}</span> */}
            <span>{formatTime(timeState)}</span>
            {/* { "09:22:40"} */}
          </TextWrapper>
        </BottomCWrapper>
      </ContentWrapper>
    </OnboardingWrapper>
  );
};

export default React.memo(CompletedGame);
