import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { ContentWrapper, NextBtn, OnboardingWrapper, TextBtn } from '../gamePlay/styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appImages } from '../../themes/imageConstant';
import { appColors } from '../../themes/colorConst';
import { CrossImg, FilterContainer, FilterLabel, MainContainer, SectionContainer, StarImg, TextWrapper } from './styles';
import ModalCommon from '../../components/modal';
import { RecordCardsContainer } from './styles';
import { CardContainer } from './styles';
import { CardLabel } from './styles';
import { CardValue } from './styles';
import { StarContainer } from './styles';
import { StarRowContainer } from './styles';
import { StarRowLabel } from './styles';
import { StarRowValue } from './styles';
import PopoverCommon from '../../components/popover';
import { useDispatch } from 'react-redux';
import { getProfileInitiate, updateGamePlayStates } from '../../redux/actions/game';
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

const YourPerformance = (props) => {

  const dispatch = useDispatch()
  const gaEventTrackerForInfo = useAnalyticsEventTracker('Info');

  const {
    isPerformanceVisible,
    profileData,
    handleClosePerformanceScreen,
    handleChangeFilterValue,
    selectedFilter
  } = props

  const [isRatingModalVisible, setIsRatingModalVisible] = React.useState(false);
  const [isFilterPopupVisible, setIsFilterPopupVisible] = React.useState(null);
  // const [selectedFilter, setSelectedFilter] = React.useState('All');

  const handleCloseModal = () => {
    setIsRatingModalVisible(false);
  }

  const handleClickFilterButton = (event) => {
    setIsFilterPopupVisible(event.currentTarget);
  };
  const handleCloseFilterPopup = (event) => {
    setIsFilterPopupVisible(null);
  };

  const getPercentage = (totalGames, winGames) => {
    let percentage = 0
    if (totalGames > 0) {
      if (winGames < 0) {
        return percentage
      } else {
        percentage = ((winGames / totalGames) * 100)
      }
    }

    return Math.round(percentage)
  }

  // React.useEffect(() => {
  //   return () => {
  //     handleClosePerformanceScreen()
  //   }
  // }, [])

  const open = Boolean(isFilterPopupVisible);
  const id = open ? 'simple-popover' : undefined;

  const popOverContent = (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '10px'
    }}>
      <div style={{ display: 'flex', gap: '15px'}}>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={'#CECECE'}
        border={'1px solid'}
        borderColor={appColors.blackColor}
        onClick={() =>  {
          handleChangeFilterValue('All')
          setIsFilterPopupVisible(null)
        }}
      >
        <TextBtn
          color={appColors.greyTextColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          ALL
        </TextBtn>
      </NextBtn>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={appColors.blackColor}
        onClick={() => {
          // setSelectedFilter('Fiendish')
          handleChangeFilterValue('Fiendish')
          setIsFilterPopupVisible(null)
          // dispatch(updateGamePlayStates('Fiendish', 'selectedFilter'))
        }}
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          FIENDISH
        </TextBtn>
      </NextBtn>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={appColors.veryHardColor}
        onClick={() => {
          // setSelectedFilter('Very Hard')
          handleChangeFilterValue('Very Hard')
          setIsFilterPopupVisible(null)
        }}
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          VERY HARD
        </TextBtn>
      </NextBtn>
      </div>
      <div style={{ display: 'flex', gap: '15px'}}>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={appColors.hardColor}
        onClick={() => {
          // setSelectedFilter('Hard')
          handleChangeFilterValue('Hard')
          setIsFilterPopupVisible(null)
        }}
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          HARD
        </TextBtn>
      </NextBtn>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={appColors.moderateColor}
        onClick={() => {
          // setSelectedFilter('Moderate')
          handleChangeFilterValue('Moderate')
          setIsFilterPopupVisible(null)
        }}
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          MODERATE
        </TextBtn>
      </NextBtn>
      <NextBtn
        aria-describedby={id}
        variant="contained"
        size="small"
        height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
        width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
        backgroundcolor={appColors.successBtnColor}
        onClick={() => {
          // setSelectedFilter('Easy')
          handleChangeFilterValue('Easy')
          setIsFilterPopupVisible(null)
        }}
      >
        <TextBtn
          color={appColors.whiteColor}
          fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
          fontFamily={'poppins500'}
        >
          EASY
        </TextBtn>
      </NextBtn>
      </div>
    </div>
  )

  const list = (anchor) => (
    <OnboardingWrapper>
      <ContentWrapper id={"contentContainer"}>
        <MainContainer cstmpadding={screenWidth > maximumTabletWidth ? "15px 0" : (15 / screenWidth) * 100 + "vh 0"}>
          <SectionContainer postionrelative={'relative'}>
            <div style={{ height: '15px' }}>
              <CrossImg
                onClick={() => handleClosePerformanceScreen()}
                src={appImages.cross}
              />
            </div>

          </SectionContainer>
          <SectionContainer>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins500'}
              color={appColors.greyTextColor}
            >
              Your Performance
            </TextWrapper>
          </SectionContainer>
          <SectionContainer>
            <RecordCardsContainer>
              <CardContainer>
                <CardValue>{profileData?.totalGame || 0}</CardValue>
                <CardLabel>Games Played</CardLabel>
              </CardContainer>
              <CardContainer>
                <CardValue>{getPercentage(profileData?.totalGame, (profileData?.completedGame - profileData?.zeroStar))}%</CardValue>
                <CardLabel>Solve %</CardLabel>
              </CardContainer>
              <CardContainer>
                <CardValue>{profileData?.streak || 0}</CardValue>
                <CardLabel>Streak</CardLabel>
              </CardContainer>
            </RecordCardsContainer>
          </SectionContainer>
          <SectionContainer>
            <StarContainer>
              <SectionContainer
                gap={screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"}
              >
                <TextWrapper
                  fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
                  fontfamily={'poppins400'}
                  color={appColors.greyTextColor}
                  positionrelative={'relative'}
                >
                  Previous Games
                </TextWrapper>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                <img
                  src={appImages.info}
                  onClick={() => {
                    setIsRatingModalVisible(true)
                    gaEventTrackerForInfo(`Info_Clicks`, 'info_clicks', 'total_count')
                  }}
                  style={{ position: 'absolute', cursor: 'pointer', paddingLeft: screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth) * 100 + "vw"}}
                  
                />
                </div>
              </SectionContainer>
              <StarRowContainer>
                <StarRowLabel>
                  {profileData?.fiveStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                </StarRowValue>
              </StarRowContainer>
              <StarRowContainer>
                <StarRowLabel>
                {profileData?.fourStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                </StarRowValue>
              </StarRowContainer>
              <StarRowContainer>
                <StarRowLabel>
                {profileData?.threeStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                </StarRowValue>
              </StarRowContainer>
              <StarRowContainer>
                <StarRowLabel>
                {profileData?.twoStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                </StarRowValue>
              </StarRowContainer>
              <StarRowContainer>
                <StarRowLabel>
                {profileData?.oneStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                </StarRowValue>
              </StarRowContainer>
              <StarRowContainer>
                <StarRowLabel>
                {profileData?.zeroStar || 0}
                </StarRowLabel>
                <StarRowValue>
                  <StarImg
                    src={appImages.starOutline}
                  />
                </StarRowValue>
              </StarRowContainer>
              <FilterContainer>
                <FilterLabel>
                  Filter by difficulty:
                </FilterLabel>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}>
                  <NextBtn
                    aria-describedby={id}
                    variant="contained"
                    size="small"
                    height={screenWidth > maximumTabletWidth ? "31px" : (31 / screenWidth * 100) + "vw"}
                    width={screenWidth > maximumTabletWidth ? "79px" : (79 / screenWidth * 100) + "vw"}
                    backgroundcolor={
                      selectedFilter == 'All' ?
                      '#CECECE' :
                      selectedFilter == 'Fiendish' ?
                      appColors.blackColor :
                      selectedFilter == 'Very Hard' ?
                      appColors.veryHardColor :
                      selectedFilter == 'Hard' ?
                      appColors.hardColor :
                      selectedFilter == 'Moderate' ?
                      appColors.moderateColor :
                      appColors.successBtnColor
                    }
                    onClick={handleClickFilterButton}
                  >
                    <TextBtn
                      color={
                        selectedFilter == 'All' ?
                        appColors.greyTextColor :
                        appColors.whiteColor
                      }
                      fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
                      fontFamily={'poppins500'}
                    >
                      {selectedFilter}
                    </TextBtn>
                  </NextBtn>
                  <img
                    onClick={handleClickFilterButton}
                    aria-describedby={id}
                    src={isFilterPopupVisible ? appImages.arrowUp : appImages.arrowDown}
                    style={{ cursor: 'pointer'}}
                  />
                </div>
                <PopoverCommon
                  isFilterPopupVisible={isFilterPopupVisible}
                  id={id}
                  handleCloseFilterPopup={handleCloseFilterPopup}
                  popOverContent={popOverContent}
                />
              </FilterContainer>
            </StarContainer>
          </SectionContainer>
        </MainContainer>
        {/* <img
            src={appImages.cross}
            height={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
            width={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
          /> */}
      </ContentWrapper>
    </OnboardingWrapper>
  );

  return (
    <div>

      <React.Fragment key={'bottom'}>

        <Drawer
          anchor={isPerformanceVisible ? 'bottom' : 'bottom'}
          // anchor={'bottom'}
          open={isPerformanceVisible}
          onClose={() => {
            // toggleDrawer(anchor, false)
          }}
          transitionDuration={{
            enter: 500,
            exit: 500
          }}
        >
          {list('bottom')}
        </Drawer>
      </React.Fragment>
      <ModalCommon
        isRatingModalVisible={isRatingModalVisible}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}

export default React.memo(YourPerformance)