import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BoxContainer, CrossImg, MainContainer, ModalContainer, SectionContainer, StarContainer, StarImg, StarRowContainer, StarRowLabel, StarRowValue, TextWrapper } from './styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";



const ModalShare = (props) => {

  const {
    isShareModalVisible,
    handleCloseModal,
    yourResult
  } = props

  const title = `I managed to Change It in ${yourResult?.attempt || 10}, can you beat me?`
  const url = `www.google.com`

  return (
    <ModalContainer
      open={isShareModalVisible}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxContainer>
        <MainContainer cstmpadding={screenWidth > maximumTabletWidth ? "10px 0" : (10 / screenWidth) * 100 + "vh 0"}>
          <SectionContainer postionrelative={'relative'} textalign={'center'}>
            <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
            >
              Share
            </TextWrapper>
            <CrossImg
              onClick={() => handleCloseModal()}
              src={appImages.cross}
            />
          </SectionContainer>
          <SectionContainer
            gap={screenWidth > maximumTabletWidth
              ? "20px"
              : (20 / screenWidth) * 100 + "vw"}
          >
            <div>
              <FacebookShareButton
                url={url}
                title={title}
                // className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

            </div>
            <div className="Demo__some-network">
            <WhatsappShareButton
               url={url}
               title={title}
              separator=" "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
      </div>
          </SectionContainer>
        </MainContainer>

      </BoxContainer>
    </ModalContainer>
  )
}

export default ModalShare
