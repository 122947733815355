import { takeLatest } from "redux-saga/effects";
import { types } from "../actionTypes";
import {
  botStepsSaga,
    checkUserExistSaga,
    completeOnboardingSaga,
    getWordSaga,
    profileDataSaga,
    timeLeftSaga,
    validateWordSaga,
    yourPerformanceSaga,
    yourResultSaga,
    userEventsSaga,
    viewSolutionClickSaga,
    restartClickSaga
} from "./game";


export default function* watcherSaga() {
  yield takeLatest(types.IS_USER_EXIST_LOAD, checkUserExistSaga);
  yield takeLatest(types.GET_WORD_LOAD, getWordSaga);
  yield takeLatest(types.COMPLETE_ONBOARDING_LOAD, completeOnboardingSaga);
  yield takeLatest(types.VALIDATE_WORD_LOAD, validateWordSaga);
  yield takeLatest(types.YOUR_PERFORMANCE_LOAD, yourPerformanceSaga);
  yield takeLatest(types.YOUR_RESULT_LOAD, yourResultSaga);
  yield takeLatest(types.BOT_STEPS_LOAD, botStepsSaga);
  yield takeLatest(types.GET_TIME_LOAD, timeLeftSaga);
  yield takeLatest(types.GET_PROFILE_LOAD, profileDataSaga);
  yield takeLatest(types.USER_EVENTS_LOAD, userEventsSaga);
  yield takeLatest(types.VIEW_SOLUTION_CLICK_LOAD, viewSolutionClickSaga);
  yield takeLatest(types.RESTART_GAME_LOAD, restartClickSaga);
  
}
