import { put, call } from "redux-saga/effects";
import { internetCheck, navigateCommon, toastCommon } from "../../utils";
import { botStepsApi, completeOnboardingApi, getQuestion, isUserExist, profileApi, restartClick, resultApi, timeLeftApi, userEventsClick, validateWord, viewSolutionClick } from "../../api";
import { isUserExistFail, isUserExistSuccess, getWordSuccess, getWordFail, completeOnboardingSuccess, completeOnboardingFail, validateWordSuccess, validateWordFail, yourPerformanceInitiate, yourPerformanceFail, yourPerformanceSuccess, yourResultSuccess, yourResultFail, botStepsSuccess, botStepsFail, getTimeSuccess, getTimeFail, getProfileSuccess, getProfileFail, isUserExistInitiate, userEventsSuccess, userEventsFail, viewSolutionClickSuccess, viewSolutionClickFail, restartClickSuccess, restartClickFail } from "../actions/game";

export function* checkUserExistSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(isUserExist, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      if (response?.data?.data?.userExist) {
        yield(navigate('/game'))
      }
      yield put(isUserExistSuccess(response?.data?.data));
      
    } else {
      yield put(isUserExistFail(response));
    }
  } catch (error) {
    yield put(isUserExistFail(error));
  }
}

export function* getWordSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(getQuestion, action.payload);

    if (response?.status >= 200 && response?.status < 400) {
      yield put(getWordSuccess(response?.data?.data));
      
    } else {
      yield put(getWordFail(response));
    }
  } catch (error) {
    yield put(getWordFail(error));
  }
}

export function* completeOnboardingSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(completeOnboardingApi, action.payload);

    if (response?.status >= 200 && response?.status < 400) {
      navigate('/game')
      yield put(completeOnboardingSuccess(response?.data?.data));
      if (action?.payload?.checkGameData) {
        yield put(isUserExistInitiate())
      }
    } else {
      yield put(completeOnboardingFail(response));
    }
  } catch (error) {
    yield put(completeOnboardingFail(error));
  }
}

export function* validateWordSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(validateWord, action.payload);

    if (response?.status >= 200 && response?.status < 400) {
      yield put(validateWordSuccess(response?.data));
    } else {
      yield put(validateWordFail(response));
    }
  } catch (error) {
    yield put(validateWordFail(error));
  }
}

export function* yourPerformanceSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(resultApi, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(yourPerformanceSuccess(response?.data?.data));
    } else {
      yield put(yourPerformanceFail(response));
    }
  } catch (error) {
    yield put(yourPerformanceFail(error));
  }
}

export function* yourResultSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(resultApi, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(yourResultSuccess(response?.data?.data));
    } else {
      yield put(yourResultFail(response));
    }
  } catch (error) {
    yield put(yourResultFail(error));
  }
}

export function* botStepsSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(botStepsApi, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(botStepsSuccess(response?.data?.data));
    } else {
      yield put(botStepsFail(response));
    }
  } catch (error) {
    yield put(botStepsFail(error));
  }
}

export function* timeLeftSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(timeLeftApi, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(getTimeSuccess(response?.data?.data));
    } else {
      yield put(getTimeFail(response));
    }
  } catch (error) {
    yield put(getTimeFail(error));
  }
}

export function* profileDataSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(profileApi, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(getProfileSuccess(response?.data?.data));
    } else {
      yield put(getProfileFail(response));
    }
  } catch (error) {
    yield put(getProfileFail(error));
  }
}

export function* userEventsSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(userEventsClick, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(userEventsSuccess(response?.data?.data));
    } else {
      yield put(userEventsFail(response));
    }
  } catch (error) {
    yield put(userEventsFail(error));
  }
}

export function* viewSolutionClickSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(viewSolutionClick, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(viewSolutionClickSuccess(response?.data?.data));
    } else {
      yield put(viewSolutionClickFail(response));
    }
  } catch (error) {
    yield put(viewSolutionClickFail(error));
  }
}

export function* restartClickSaga(action) {
  const navigate = navigateCommon.get()
  try {
    let response = yield call(restartClick, action.payload);
    if (response?.status >= 200 && response?.status < 400) {
      yield put(restartClickSuccess(response?.data?.data));
    } else {
      yield put(restartClickFail(response));
    }
  } catch (error) {
    yield put(restartClickFail(error));
  }
}