import moment from "moment";
import { convertToLocalTime } from "../../utils";
import { types } from "../actionTypes";

const initialStates = {
  isUserExist: false,
  isUserExistSuccess: false,
  isUserExistLoader: false,
  gameData: null,
  words: null,
  wordLoader: false,
  wordSuccess: false,
  completedOnboarding: false,
  completedOnboardingLoader: false,
  completedOnboardingSuccess: false,
  validateWordSuccess: false,
  validateWordLoader: false,
  validateResponse: null,
  yourPerformance: null,
  yourPerformanceSuccess: false,
  yourPerformanceLoader: false,
  yourResult: null,
  yourResultSuccess: false,
  yourResultLoader: false,
  botSteps: null,
  botStepsCount: 0,
  botStepsSuccess: false,
  botStepsLoader: false,
  challengeDifficulty: null,
  time: null,
  timeLoader: false,
  timeSuccess: false,
  profileData: null,
  profileDataSuccess: false,
  profileDataLoader: false,
  selectedFilter: 'All',
  gameCompleted: false,
  getFirstGame: [],
  userEventsLoad: false,
  userEventsSuccess: false,
  viewSolutionClickLoader: false,
  viewSolutionClickSuccess: false,
  viewSolution: false,
  restartGameLoad: false,
  restartGameSuccess: false,
  viewSolutionForWord: false
}

const game = (state = initialStates, { payload, type, key }) => {
  switch (type) {
    case types.IS_USER_EXIST_LOAD:
      return {
        ...state,
        isUserExistLoader: true
      };

    case types.IS_USER_EXIST_SUCCESS:
      if (!payload?.userExist) {
        document.cookie = `cookieName=${payload?.token}; max-age=31536000; path=/`;
      }
      let tempGameData = null
      if (payload?.gameData) {
        tempGameData = payload?.gameData
      }

      localStorage.setItem('isGamePlayed', payload?.isGamePlayed)

      return {
        ...state,
        isUserExist: payload?.userExist,
        completedOnboarding: payload?.onBoardingComplete,
        gameCompleted: payload?.isGamePlayed,
        getFirstGame: payload?.getFirstGame,
        isUserExistLoader: false,
        isUserExistSuccess: true,
        gameData: tempGameData
      };

    case types.IS_USER_EXIST_FAIL:
      return {
        ...state,
        isUserExistLoader: false
      };

    case types.GET_WORD_LOAD:
      return {
        ...state,
        wordLoader: true
      };

    case types.GET_WORD_SUCCESS:
      return {
        ...state,
        isUserExist: payload?.userExist,
        words: payload?.question,
        botSteps: payload?.wordArray,
        botStepsCount: payload?.count,
        challengeDifficulty: payload?.challengeDifficulty,
        wordLoader: false,
        wordSuccess: true,
        wordId: payload?._id,
        restartCount: payload?.restartCount || 0,
        viewSolutionForWord: payload?.viewSolution
      };

    case types.GET_WORD_FAIL:
      return {
        ...state,
        wordLoader: false
      };

    case types.COMPLETE_ONBOARDING_LOAD:
      return {
        ...state,
        completedOnboardingLoader: true
      };

    case types.COMPLETE_ONBOARDING_SUCCESS:
      return {
        ...state,
        completedOnboarding: payload?.onBoardingComplete,
        completedOnboardingLoader: false,
        completedOnboardingSuccess: true,
        isUserExist: payload?.userExist
      };

    case types.COMPLETE_ONBOARDING_FAIL:
      return {
        ...state,
        completedOnboardingLoader: false
      };

    case types.VALIDATE_WORD_LOAD:
      return {
        ...state,
        validateWordLoader: true
      };

    case types.VALIDATE_WORD_SUCCESS:
      return {
        ...state,
        validateWordLoader: false,
        validateWordSuccess: true,
        validateResponse: payload,
      };

    case types.VALIDATE_WORD_FAIL:
      return {
        ...state,
        validateWordLoader: false
      };

    case types.YOUR_PERFORMANCE_LOAD:
      return {
        ...state,
        yourPerformanceLoader: true
      };

    case types.YOUR_PERFORMANCE_SUCCESS:
      return {
        ...state,
        yourPerformanceLoader: false,
        yourPerformanceSuccess: true,
        yourPerformance: payload.action,
      };

    case types.YOUR_PERFORMANCE_FAIL:
      return {
        ...state,
        yourPerformanceLoader: false
      };

    case types.YOUR_RESULT_LOAD:
      return {
        ...state,
        yourResultLoader: true
      };

    case types.YOUR_RESULT_SUCCESS:
      return {
        ...state,
        yourResultLoader: false,
        yourResultSuccess: true,
        yourResult: payload,
      };

    case types.YOUR_RESULT_FAIL:
      return {
        ...state,
        yourResultLoader: false
      };

      case types.BOT_STEPS_LOAD:
        return {
          ...state,
          botStepsLoader: true
        };
  
      case types.BOT_STEPS_SUCCESS:
        return {
          ...state,
          botStepsLoader: false,
          botStepsSuccess: true,
          // botSteps: payload,
        };
  
      case types.BOT_STEPS_FAIL:
        return {
          ...state,
          botStepsLoader: false
        };

        case types.GET_TIME_LOAD:
          return {
            ...state,
            timeLoader: true
          };
    
        case types.GET_TIME_SUCCESS:
          

          // Get the local time equivalent of the remaining duration
          // const localEndTime = moment(payload?.time);
          // const hours = String(localEndTime.hours()).padStart(2, '0');
          // const minutes = String(localEndTime.minutes()).padStart(2, '0');
          // const seconds = String(localEndTime.seconds()).padStart(2, '0');
          // let localTime = `${hours}:${minutes}:${seconds}`
          return {
            ...state,
            timeLoader: false,
            timeSuccess: true,
            time: payload?.time,
          };
    
        case types.GET_TIME_FAIL:
          return {
            ...state,
            timeLoader: false
          };

          case types.GET_PROFILE_LOAD:
        return {
          ...state,
          profileDataLoader: true
        };
  
      case types.GET_PROFILE_SUCCESS:
        return {
          ...state,
          profileDataLoader: false,
          profileDataSuccess: true,
          profileData: payload,
        };
  
      case types.GET_PROFILE_FAIL:
        return {
          ...state,
          profileDataLoader: false
        };

        case types.USER_EVENTS_LOAD:
        return {
          ...state,
          userEventsLoad: true
        };
  
      case types.USER_EVENTS_SUCCESS:
        return {
          ...state,
          userEventsLoad: false,
          userEventsSuccess: true,
        };
  
      case types.USER_EVENTS_FAIL:
        return {
          ...state,
          userEventsLoad: false
        };

        case types.VIEW_SOLUTION_CLICK_LOAD:
          return {
            ...state,
            viewSolutionClickLoader: true
          };
    
        case types.VIEW_SOLUTION_CLICK_SUCCESS:
          return {
            ...state,
            viewSolutionClickLoader: false,
            viewSolutionClickSuccess: true,
            viewSolution: payload?.viewSolution
          };
    
        case types.VIEW_SOLUTION_CLICK_FAIL:
          return {
            ...state,
            viewSolutionClickLoader: false
          };

          case types.RESTART_GAME_LOAD:
          return {
            ...state,
            restartGameLoad: true
          };
    
        case types.RESTART_GAME_SUCCESS:
          console.log("this is payload = ", payload)
          return {
            ...state,
            restartGameLoad: false,
            restartCount: payload?.restartCount,
            restartGameSuccess: true,
          };
    
        case types.RESTART_GAME_FAIL:
          return {
            ...state,
            restartGameLoad: false
          };

    case types.UPDATE_GAME_PLAY_STATES:
      state[key] = payload;
      return { ...state };

    default:
      return state;
  }
}

export default game;